import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import portret_alicja from '../images/portret_alicja.jpg';
import portret_masha from '../images/portret_masha.jpg';
import portret_monika from '../images/portret_monika.jpg';
import portret_paulina from '../images/portret_paulina.jpg';
import portret_milosz from '../images/portret_milosz.jpg';
import portret_aneta from '../images/portret_aneta.jpg';
import portret_anna from '../images/portret_anna.jpg';
import portret_magda from '../images/portret_magda.jpg';
import portret_daria from '../images/portret_daria.jpg';

const TeamPage = () => {
    return (
        <Layout>
            <Seo title="Nasz Zespół"/>
            <section className='team-container'>
                <section className='team-intro'>
                    <h1 className='team-intro_title'>Poznaj Nasz Zespół</h1>
                    <h2 className='team-intro_subtitle'>Jesteśmy zespołem dyplomowanych i certyfikowanych instruktorów metody Pilates w nurcie współczesnym. Każda z osób ma niezwykłe doświadczenie i profil zawodowy.</h2>
                </section>
                <div className='team-intro'>
                    <h2 className='team-intro_subtitle team-hero_team-name'>Specjalistyczny zespół Pilatesu terapeutycznego:</h2>
                </div>
                <section className='team-hero'>
                    <Link to='/zespol-alicja' className='team-hero_card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_alicja} alt="Instruktorka Alicja Rukowicz" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Alicja Rukowicz</h3>
                                <p className='team-hero_card_information-description'>Właścicielka Studia Pilatesu terapeutycznego, dyplomowana instruktorka Pilatesu terapeutycznego, certyfikowany trener wielu metod pracy z ciałem, lingwista, marketingowiec i pedagog.
                                </p>
                            </div>
                            <div className='team-hero_card_information-lang'><span>Dowiedz się więcej...</span></div>
                        </div>
                    </Link>
                    <Link to='/zespol-paulina' className='team-hero_card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_paulina} alt="Instruktorka Paulina Benitez" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Paulina Benitez</h3>
                                <p className='team-hero_card_information-description'>Dyplomowana instruktorka Pilatesu, tańca i Stretchingu, lingwista.
                                </p>
                            </div>
                            <div className='team-hero_card_information-lang'><span>Dowiedz się więcej...</span></div>
                        </div>
                    </Link>
                    <Link to='/zespol-monika' className='team-hero_card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_monika} alt="Instruktorka Monika Adamiec" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Monika Adamiec</h3>
                                <p className='team-hero_card_information-description'>Certyfikowana instruktorka Pilatesu, socjolożka i pedagożka specjalna.
                                </p>
                            </div>
                            <div className='team-hero_card_information-lang'><span>Dowiedz się więcej...</span></div>
                        </div>
                    </Link>
                    <Link to='/zespol-milosz' className='team-hero_card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_milosz} alt="Instruktor Miłosz Kaźmierczak" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Miłosz Kaźmierczak</h3>
                                <p className='team-hero_card_information-description'>Dyplomowany instruktor Pilates, biolog.
                                </p>
                            </div>
                            <div className='team-hero_card_information-lang'><span>Dowiedz się więcej...</span></div>
                        </div>
                    </Link>
                    <Link to='/zespol-aneta' className='team-hero_card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_aneta} alt="Instruktorka Aneta Kosmowska" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Aneta Kosmowska</h3>
                                <p className='team-hero_card_information-description'>Certyfikowana instruktorka Pilatesu, pedagog tańca, artystka baletu Teatru Wielkiego w Łodzi, studentka amerykańskiej szkoły Pilatesu Balanced Body.
                                </p>
                            </div>
                            <div className='team-hero_card_information-lang'><span>Dowiedz się więcej...</span></div>
                        </div>
                    </Link>
                    
                    <Link to='/zespol-anna' className='team-hero_card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_anna} alt="Instruktorka Anna Rostek" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Anna Rostek</h3>
                                <p className='team-hero_card_information-description'>Certyfikowana instruktorka Pilatesu oraz Holistic Wellness Coach
                                </p>
                            </div>
                            <div className='team-hero_card_information-lang'><span>Dowiedz się więcej...</span></div>
                        </div>
                    </Link>
                    
                    
                </section>
                <div className='team-intro'>
                    <h2 className='team-intro_subtitle team-hero_team-name'>Zespół współpracujący:</h2>
                </div>
                <section className='team-hero'>
                    <Link to='/zespol-masha' className='team-hero_card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_masha} alt="Instruktorka Masha Zhuk" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Masha Zhuk</h3>
                                <p className='team-hero_card_information-description'>Dyplomowana instruktorka Pilatesu, wieloletnia pierwsza solistka Polskiego Baletu Narodowego.
                                </p>
                            </div>
                            <div className='team-hero_card_information-lang'><span>Dowiedz się więcej...</span></div>
                        </div>
                    </Link>
                    <Link to='/zespol-magda' className='team-hero_card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_magda} alt="Instruktorka Magda Sobieska" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Magdalena Sobieska</h3>
                                <p className='team-hero_card_information-description'>Dyplomowana instruktorka Pilatesu oraz Stretchingu, Psycholog i Choreoterapeuta w Edukacji, aktorka teatru tańca, tancerka
                                </p>
                            </div>
                            <div className='team-hero_card_information-lang'><span>Dowiedz się więcej...</span></div>
                        </div>
                    </Link>
                    <Link to='/zespol-daria' className='team-hero_card'>
                        <div className='team-hero_card_picture-container'>
                            <img src={portret_daria} alt="Instruktorka Daria Kyiko" className='team-hero_card-picture-picture'></img>
                        </div>
                        <div className='team-hero_card_information'>
                            <div className='team-hero_card_information_separator'>
                                <h3 className='team-hero_card_information-name'>Daria Kyiko</h3>
                                <p className='team-hero_card_information-description'>Instruktorka Pilatesu, studentka międzynarodowej Szkoły Pilatesu POLESTAR
                                </p>
                            </div>
                            <div className='team-hero_card_information-lang'><span>Dowiedz się więcej...</span></div>
                        </div>
                    </Link>
                </section>
            </section>
        </Layout>
    )
}

export default TeamPage;